import React from "react";
import moment from "moment";
import styled from "styled-components";

const OrderTimeComponent = styled.span`
    float: right;
    font-weight: bold;
`;

function OrderTime (props) {
    let dateTime = new Date(props.datetime);
    let formattedTime;
    if (dateTime.getDay() === new Date().getDay()) {
        formattedTime = moment(dateTime).format('LT');
    } else {
        formattedTime = moment(dateTime).format('LLL');
    }

    return (
        <OrderTimeComponent>{formattedTime}</OrderTimeComponent>
    )
}

export default OrderTime;