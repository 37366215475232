import {NavLink} from "react-router-dom";
import {logout} from "../../redux/actions";
import React from "react";
import styled from "styled-components";
import {DeliveryTimeMenu} from "../DeliveryTimeMenu/DeliveryTimeMenu";

const StyledBoardMenu = styled.footer`
  grid-area: menu;
  background-color: #65dc90;
  padding: 15px;
  box-shadow: 0px 3px 5px 5px rgba(0,0,0,0.26);
 .selected button {
      background-color: #cecece;
  }
`

const StyledMenuButton = styled.button`
  background-color: #f5f5f5;
  color: #000000;
  padding: 10px;
  font-size: 1em;
  text-transform: uppercase;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #1d1d1d;
  margin: 0 5px 0 5px;
 
`

const StyledCloseButton = styled(StyledMenuButton)`
  float: left;
  margin-left: 0;
`

const StyledLogoutButton = styled(StyledMenuButton)`
  float: right;
  margin-right: 0;
`

function BoardMenu(props) {
    let {onClose} = props;

    function handleClose(e) {
        e.preventDefault();
        onClose();
    }

    const TabsButtons = (
        <div>
            <NavLink to="/kitchen" activeClassName={"selected"}>
                <StyledMenuButton>Kuchnia / Dostawa</StyledMenuButton>
            </NavLink>
            <NavLink to="/completed" activeClassName={"selected"}>
                <StyledMenuButton>Zakończone</StyledMenuButton>
            </NavLink>
            <DeliveryTimeMenu/>
            <StyledLogoutButton onClick={logout}>Wyloguj</StyledLogoutButton>
        </div>
    )

    const CloseButton = (
        <StyledCloseButton onClick={handleClose}>Zamknij</StyledCloseButton>
    )
    return (
    <StyledBoardMenu>
        {onClose ? CloseButton : TabsButtons}
    </StyledBoardMenu>
    )
}

export default BoardMenu;
