import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'moment/locale/pl';
import moment from "moment";
import * as Sentry from '@sentry/browser'
import { CaptureConsole } from '@sentry/integrations'

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SOURCE_VERSION) {
    console.log('Initialising Sentry...');
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: process.env.REACT_APP_SOURCE_VERSION,
        integrations: [
            new CaptureConsole({
              levels: ['error']
            })
        ],
    });
}

const locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
