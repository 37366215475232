import React, {useState} from "react";
import {connect} from "react-redux";
import {updateOrderStatus, openOrderDetails} from "../../redux/actions";
import styled from "styled-components";

const StyledTileMenuButton = styled.button`
  padding: 5px;
  margin: 5px;
  border: 1px solid #494949;
  border-radius: 3px;
  text-transform: uppercase;
`

function OrderTileMenu (props) {
    const [isLoading, setLoading] = useState(false);

    const { orderId, nextStatus, nextStatusMessage, onClose, updateOrderStatus, openOrderDetails } = props;

    function handleClose(e) {
        e.preventDefault();
        onClose();
    }

    function handleOpenOrderDetails (e)  {
        e.preventDefault();
        openOrderDetails(orderId);
    }

    function handleUpdateOrderStatus (e) {
        e.preventDefault();
        setLoading(true);
        updateOrderStatus(orderId, nextStatus);
    }


    if (isLoading === true) {
        return (
            <div><p>Ładowanie...</p></div>
        )
    } else {
        return (
            <div>
                <StyledTileMenuButton onClick={handleOpenOrderDetails}>Szczegóły</StyledTileMenuButton>
                {nextStatusMessage ? <StyledTileMenuButton onClick={handleUpdateOrderStatus}>{nextStatusMessage}</StyledTileMenuButton> : null}
                <StyledTileMenuButton onClick={handleClose}>Zamknij</StyledTileMenuButton>
            </div>
        )
    }
}


const ConnectedOrderTileMenu = connect(null, {updateOrderStatus, openOrderDetails})(OrderTileMenu);
export { ConnectedOrderTileMenu as OrderTileMenu };