import {
    ORDERS_REFRESHED,
    ORDERS_REFRESH_FAILED,
    SET_MODE,
    ORDERS_REFRESHING,
    ORDER_STATUS_UPDATED,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    ACCESS_TOKEN_REFRESHED,
    OPEN_ORDER_DETAILS,
    CLOSE_ORDER_DETAILS,
    OPEN_ORDER_MENU,
    CLOSE_ORDER_MENU,
    DELIVERY_TIME_ESTIMATE_UPDATED,
} from './actionTypes'
import { combineReducers } from "redux";

const ordersState = {
    orders: [],
    orderMap: {},
    isLoaded: false,
    isRefreshing: false,
    lastRefreshedAt: null,
    detailsOrderId: null,
    openMenuOrderId: null,
};

function ordersReducer(state=ordersState, action) {
    switch (action.type) {

        case ORDERS_REFRESHING:
            return Object.assign({}, state, {
                isRefreshing: true,
            });

        case ORDERS_REFRESH_FAILED:
            return Object.assign({}, state, {
                isRefreshing: false,
            });

        case ORDERS_REFRESHED:
            const refreshedTime = new Date();
            const refreshedOrders = action.payload;
            const existingOrdersMap = state.orderMap;
            const ordersAfterRefresh = refreshedOrders.map(refreshedOrder => {
                if (existingOrdersMap[refreshedOrder.id] === undefined) {
                    // if order does not exist in current orders, use the new version
                    return refreshedOrder;
                }
                const existingOrder = existingOrdersMap[refreshedOrder.id];
                const existingOrderUpdatedAt = new Date(existingOrder.updated_at);
                const refreshedOrderUpdatedAt = new Date(refreshedOrder.updated_at);
                if (existingOrderUpdatedAt < refreshedOrderUpdatedAt) {
                    // if refreshed order is newer than existing one, update it
                    return refreshedOrder;
                } else {
                    // if refreshed order is older than the one in state, e.g. because we just got a cached
                    // version of an order which status got update, leave the one from the state
                    return existingOrder;
                }
            })
            return Object.assign({}, state, {
                isLoaded: true,
                isRefreshing: false,
                lastRefreshedAt: refreshedTime,
                orderMap: Object.assign({}, ...ordersAfterRefresh.map(order => ({[order.id]: order}))),
                orders: ordersAfterRefresh,
            });

        case ORDER_STATUS_UPDATED:
            const orderId = action.payload.orderId;
            const updatedOrderData = action.payload.orderData;
            const ordersAfterUpdate = state.orders.map((order) => {
                // update the order in array
                if (order.id === updatedOrderData.id) {
                    return updatedOrderData;
                } else {
                    return order;
                }

            })
            return Object.assign({}, state, {
                orderMap: {
                    ...state.orderMap,
                    [orderId]: updatedOrderData,
                },
                // close menu if it's the one which is open
                openMenuOrderId: orderId === state.openMenuOrderId ? null : state.openMenuOrderId,
                orders: ordersAfterUpdate,
            });
        case OPEN_ORDER_DETAILS:
            return Object.assign({}, state, {
                detailsOrderId: action.payload,
            });
        case CLOSE_ORDER_DETAILS:
            return Object.assign({}, state, {
                detailsOrderId: null,
            });
        case OPEN_ORDER_MENU:
            return Object.assign({}, state, {
                openMenuOrderId: action.payload,
            });
        case CLOSE_ORDER_MENU:
            return Object.assign({}, state, {
                openMenuOrderId: null,
            });
        default:
            return state;
    }
}

const initialModeState = {
    mode: "KITCHEN",
}

function modeReducer(state = initialModeState, action) {
    if (action.type === SET_MODE) {
        return {mode: action.payload.mode};
    }
    return state;
}

let user = JSON.parse(localStorage.getItem('user'));
const initialAuthState = user ? {
    error: null,
    loggedIn: true,
    user
} : {}

function authReducer(state = initialAuthState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            console.log('LOGIN_SUCCESS RECEIVED')
            return {
                loggedIn: true,
                user: action.payload,
                error: null,
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                loggedIn: false,
                error: action.payload,
            }
        case LOGOUT:
            return {}
        case ACCESS_TOKEN_REFRESHED:
            return {
                ...state,
                user: {
                    ...state.user,
                    access: action.payload,
                },
            }

        default:
            return state
    }
}

const initialRestaurantState = {
    deliveryTimeEstimate: null
}

function restaurantReducer(state = initialRestaurantState, action) {
    switch (action.type) {
        case DELIVERY_TIME_ESTIMATE_UPDATED:
            return {
                deliveryTimeEstimate: action.payload,
            }
        default:
            return state
    }
}

const rootReducer = combineReducers({
    orders: ordersReducer,
    mode: modeReducer,
    auth: authReducer,
    restaurant: restaurantReducer
})

export default rootReducer;
