import React from 'react';
import styled from "styled-components";

const DeliveryRowComponent = styled.div`
    min-height: 20px;
    padding: 5px;
    font-size: 13px;
    font-weight: bold;
    border-bottom: 2px solid #f3f3f3;
`;

const DeliveryOption = styled.span`
    float: left;
`;

const DeliveryCity = styled.span`
    float: right;
`;

function DeliveryRow (props) {
    const { deliveryInfo } = props;


    return (
        <DeliveryRowComponent>
            <DeliveryOption>{deliveryInfo.delivery_option === "DELIVERY" ? "DOWÓZ" : "ODBIÓR"}</DeliveryOption>
            {deliveryInfo.delivery_address ? <DeliveryCity>{deliveryInfo.delivery_address.city}</DeliveryCity> : null}
        </DeliveryRowComponent>
    )
}

export default DeliveryRow;