import {getAuthHeader, getRefreshToken, updateAccessToken} from "./localStorage";
import {API_URL} from "../config";
import {refreshAccessToken as refreshAccessTokenAction, logout as logoutAction} from "../redux/actions";

export const authService = {
    login,
    logout,
    fetchJSONWithAuth
}

function fetchJSONWithAuth(url, options) {
    let mergedOptions = {
        ...options,
        headers: {
            ...options.headers,
            ...getAuthHeader(),
            'Content-Type': 'application/json',
        }
    }
    return fetch(url, mergedOptions).then(
        (response) => {
            return response.json()
                .then(json => {
                    if (response.status === 401 && json.code === "token_not_valid") {
                        console.log("Token not valid, refreshing token")
                        let refreshBody = JSON.stringify({refresh: getRefreshToken()});
                        return fetch(`${API_URL}/api/auth/token/refresh/`, {
                            method: "POST",
                            headers: {"Content-Type": "application/json"},
                            body: refreshBody
                        }).then(
                            response => {
                                console.log("Token refreshed successfully, saving")
                                return response.json().then(
                                    (user) => {
                                        let newToken = user.access;
                                        updateAccessToken(newToken);
                                        refreshAccessTokenAction(newToken);
                                        return fetchJSONWithAuth(url, options);
                                    }
                                )
                            },
                            error => {
                                console.log("Token refresh failed, logging out");
                                logoutAction();
                                return new Promise((resolve, reject) => {
                                    return reject(error)
                                })
                            }
                        )
                    } else if (response.status === 401) {
                        console.log("Invalid token, logging out");
                        logoutAction();
                        return new Promise((resolve, reject) => {
                            return reject()
                        })
                    } else {
                        console.log("Token ok, returning response");
                        return Promise.resolve(json)
                    }

                }
            )

        },
        (error) => {
            console.log('Base request error');
            return Promise.reject(error);
        }
    );
}

function login (username, password) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({username, password})
    };
    return fetch(`${API_URL}/api/auth/token/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        })
}

function logout() {
    localStorage.removeItem('user');
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
        }
    );
}