import store from './store';
import {
    ORDERS_REFRESHED,
    ORDERS_REFRESH_FAILED,
    SET_MODE,
    ORDERS_REFRESHING,
    ORDER_STATUS_UPDATED,
    LOGIN_FAILURE,
    LOGIN_SUCCESS, ACCESS_TOKEN_REFRESHED, LOGOUT,
    OPEN_ORDER_DETAILS,
    CLOSE_ORDER_DETAILS,
    OPEN_ORDER_MENU,
    CLOSE_ORDER_MENU,
    DELIVERY_TIME_ESTIMATE_UPDATED,
} from './actionTypes';
import { history } from '../history';
import { authService } from "../auth/authService";
import {API_URL} from "../config";



export function refreshOrders() {
    const orderFilters = "?created_in_last_hours=24&status=RECEIVED&status=KITCHEN_CONFIRMED&status=READY_TO_PICKUP&status=OUT_FOR_DELIVERY&status=DELIVERED";
    return function(dispatch) {
        let requestOptions = {method: "GET"}
        store.dispatch({type: ORDERS_REFRESHING, payload: {}});
        return authService.fetchJSONWithAuth(
            `${API_URL}/api/orders${orderFilters}`,
            requestOptions,
        ).catch(err => {
            store.dispatch({type: ORDERS_REFRESH_FAILED, payload: err})
            return Promise.reject(err);
        })
        .then(json => {
            if (Array.isArray(json)) {
                store.dispatch({ type: ORDERS_REFRESHED, payload: json });
            } else {
                store.dispatch({type: ORDERS_REFRESH_FAILED})
            }
        });
    };
}

export function setMode(mode) {
    store.dispatch({type: SET_MODE, payload: {mode: mode}})
}

export function updateOrderStatus(orderId, newStatus) {
    const url = `${API_URL}/api/orders/${orderId}`;
    return function(dispatch) {
        return authService.fetchJSONWithAuth(
            url, {
                method: "PATCH",
                body: JSON.stringify({status: newStatus}),
            })
            .then(json => {
                store.dispatch(
                    {type: ORDER_STATUS_UPDATED, payload: {orderId: orderId, newStatus: newStatus, orderData: json}}
                )
            }
        )
    }
}

export function login(username, password) {
    return function(dispatch) {
        authService.login(username, password)
            .then(
                user => {
                    dispatch({type: LOGIN_SUCCESS, payload: user})
                    localStorage.setItem("user", JSON.stringify(user))
                    history.push('/kitchen');
                },
                error => {
                    dispatch({type: LOGIN_FAILURE, payload: error})
                }
            )
    }
}

export function logout() {
    authService.logout();
    store.dispatch({type: LOGOUT});
    history.push('/');
}

export function refreshAccessToken(newToken) {
    store.dispatch({type: ACCESS_TOKEN_REFRESHED, payload: newToken})
}

export function openOrderDetails(orderId) {
    return {type: OPEN_ORDER_DETAILS, payload: orderId}
}

export function closeOrderDetails() {
   return {type: CLOSE_ORDER_DETAILS, payload: null}
}


export function openOrderMenu(orderId) {
    return {type: OPEN_ORDER_MENU, payload: orderId}
}

export function closeOrderMenu() {
   return {type: CLOSE_ORDER_MENU, payload: null}
}

export function fetchDeliveryTimeEstimate() {
    return function(dispatch) {
        let requestOptions = {method: "GET"}
        return authService.fetchJSONWithAuth(
            `${API_URL}/api/restaurant/status`,
            requestOptions,
        )
        .then(json => {
            store.dispatch({ type: DELIVERY_TIME_ESTIMATE_UPDATED, payload: json.order_delivery_time_estimate });
        });
    };
}

export function updateDeliveryTimeEstimate(newEstimate) {
    return function(dispatch) {
        let requestOptions = {
                method: "PATCH",
                body: JSON.stringify({order_delivery_time_estimate: newEstimate}),
            }
        return authService.fetchJSONWithAuth(
            `${API_URL}/api/restaurant/status`,
            requestOptions,
        )
        .then(json => {
            store.dispatch({ type: DELIVERY_TIME_ESTIMATE_UPDATED, payload: json.order_delivery_time_estimate });
        });
    };
}