export function getAuthHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));
    let accessToken = user.access

    if (user && accessToken) {
        return { 'Authorization': 'Bearer ' + accessToken };
    } else {
        return {};
    }
}

export function getRefreshToken() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.refresh) {
        return user.refresh;
    }
}

export function updateAccessToken(newToken) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        const newUser = {
            ...user,
            access: newToken
        }
        localStorage.setItem('user', JSON.stringify(newUser));
    }
}