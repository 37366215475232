import React from 'react';
import styled from "styled-components";
import { connect } from 'react-redux';
import { closeOrderDetails } from "../../redux/actions";
import ItemAddOn from "../ItemAddOn/ItemAddOn";

const StyledOrderDetailsWrapper = styled.div`
    padding: 10px;
    background-color: #f3f3f3;
    min-height: 0;
    
`;

const StyledOrderDetails = styled.div`
    background-color: white;
    border-radius: 5px;
    grid-area: content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100px 1fr;
    padding: 10px;
`
const StyledOrderBreakdown = styled.div`
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    padding: 20px;
`

const StyledOrderDeliveryDetails = styled.div`
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    text-align: left;
    padding: 20px;
`

const StyledOrderDetailsHeader = styled.div`
  grid-row: 1 / 2;
  grid-column: 1/3;
`

const StyledOrderBreakdownTable = styled.table`
  width: 100%;
  text-align: left;
  td {
    padding-top: 10px;
    vertical-align: top;
  }
  td.price, th.price {
    text-align: right;
  }
  tr.total {
    font-weight: bold;
  }
`
const QuantityTd = styled.td`
  text-align: left;
`
const ItemDetailsTd = styled.td`
  text-align: left;
  padding-left: 10px;
`
const PriceTd = styled.td`
    text-align: right;
`

function OrderBreakdownItemRow (props) {
    const {item} = props;
    return (
        <tr>
            <QuantityTd>{item.quantity}x</QuantityTd>
            <ItemDetailsTd>
                <div>
                    {item.name} {item.variant_name ? `(${item.variant_name})` : null}
                    {item.add_ons.map(addOn => (
                        <ItemAddOn addOn={addOn} key={addOn.name}/>
                    ))}
                </div>
            </ItemDetailsTd>
            <PriceTd>{item.total_amount.decimal_amount}</PriceTd>
        </tr>
    )
}

function OrderBreakdownTable (props) {
    const {order} = props;
    return (
            <StyledOrderBreakdownTable>
                <tbody>
                    {order.items.map((item, idx) => (
                        <OrderBreakdownItemRow key={`${order.id}-${idx}-${item.name}`} item={item}/>
                    ))}
                    <tr>
                        <td colSpan="2">Dostawa</td>
                        <td className="price">{order.delivery.total_amount.decimal_amount}</td>
                    </tr>
                    <tr className="total">
                        <td colSpan="2">Razem</td>
                        <td className="price">{order.total_amount.currency} {order.total_amount.decimal_amount}</td>
                    </tr>
                </tbody>
            </StyledOrderBreakdownTable>
        )
}

function OrderDeliveryDetails (props) {
    const {order} = props;
    let addressInfo, mapsUrl;
    if (order.delivery.delivery_address) {
        mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${order.delivery.delivery_address.address}%20${order.delivery.delivery_address.city}`;
        addressInfo = <div>
            <p>{order.delivery.delivery_address.address}</p>
            <p>{order.delivery.delivery_address.city}</p>
        </div>
    }
    return (
        <StyledOrderDeliveryDetails>
            <p><b>{order.delivery.delivery_option === "DELIVERY" ? "Dowóz" : "Odbiór"}</b></p>
            <p>{order.delivery.name}</p>
            <a href={mapsUrl} target="_blank" rel="noopener noreferrer">{addressInfo}</a>
            <p><a href="tel:{order.delivery.phone_number}">{order.delivery.phone_number}</a></p>
            <p>Dostawa bezkontaktowa: {order.delivery.contactless_delivery ? "TAK" : "NIE"}</p>
            <p>Inf od klienta: {order.delivery.customer_note ? order.delivery.customer_note : "brak"}</p>
        </StyledOrderDeliveryDetails>
    )
}


function OrderDetails (props) {
    const { order } = props;

    return (
        <StyledOrderDetailsWrapper>
            <StyledOrderDetails>
                <StyledOrderDetailsHeader>
                    <h1>Zamówienie #{order.public_id}</h1>
                </StyledOrderDetailsHeader>
                <StyledOrderBreakdown>
                    <OrderBreakdownTable order={order}/>
                </StyledOrderBreakdown>
                <OrderDeliveryDetails order={order}/>
            </StyledOrderDetails>
        </StyledOrderDetailsWrapper>
    )
}

function mapStateToProps(state) {
    return {
        order: state.orders.orderMap[state.orders.detailsOrderId],
    };
}


let ConnectedOrderDetails = connect(mapStateToProps)(OrderDetails);
export {ConnectedOrderDetails as OrderDetails}
