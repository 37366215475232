import styled, {css} from "styled-components";
import PublicId from "../PublicId/PublicId";
import OrderTime from "../OrderTime/OrderTime";
import React from "react";

const StyledOrderTileHeader = styled.div`
    min-height: 20px;
    background-color: ${props => props.color};
    ${props => props.status === 'RECEIVED' && css`
        @keyframes attention-order {
          0% {background-color: #ffe603;}
          50% {background-color: #ff6600;}
          100% {background-color: #ffe603;}
        }
        animation-name: attention-order;
        animation-duration: 1s;
        animation-iteration-count: infinite;
  `};
    padding: 5px;
    border-radius: 5px 5px 0 0;
`;

function OrderTileHeader (props) {
    const { order } = props;
    const statusToBackgroundColorMap = {
        "RECEIVED": "#ffe603",
        "KITCHEN_CONFIRMED": "#ceffce",
        "READY_TO_PICKUP": "#7aff7e",
        "OUT_FOR_DELIVERY": "#00e300",
        "DELIVERED": "#5ba85b",
    }
    const headerColor = statusToBackgroundColorMap[order.status];

    return (
        <StyledOrderTileHeader color={headerColor} status={order.status}>
            <PublicId publicId={order.public_id}/>
            <OrderTime datetime={order.paid_at}/>
        </StyledOrderTileHeader>
    )
}

export default OrderTileHeader;