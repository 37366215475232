import React from "react";
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, componentProps, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? <Component {...componentProps} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
)
