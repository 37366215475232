import React, {useEffect} from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {fetchDeliveryTimeEstimate, updateDeliveryTimeEstimate} from "../../redux/actions";


const DeliveryTimeSelectContainer = styled.div`
  float: left;
  select, label {
    display: block;
  }
  label {
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 5px;
  }
  select {
    font-size: 20px
  }
`

function DeliveryTimeMenu (props) {
    const {deliveryTimeEstimate, fetchDeliveryTimeEstimate, updateDeliveryTimeEstimate} = props;

    useEffect( () => {
        fetchDeliveryTimeEstimate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onSelectChange (e) {
        updateDeliveryTimeEstimate(e.target.value);
    }

    if (!deliveryTimeEstimate) return null;
    
    return (
        <DeliveryTimeSelectContainer>
            <select value={deliveryTimeEstimate} onChange={onSelectChange} name="delivery-time" id="delivery-time">
                <option value="30m" >do 30 minut</option>
                <option value="45m" >30 - 45 minut</option>
                <option value="60m" >45 min - 1 godzina</option>
                <option value="90m" >1 - 1,5 godziny</option>
                <option value="120m" >1,5 - 2 godziny</option>
            </select>
            <label htmlFor="delivery-time">Aktualny czas dostawy</label>
        </DeliveryTimeSelectContainer>
    )
}


function mapStateToProps(state) {
    return {
        deliveryTimeEstimate: state.restaurant.deliveryTimeEstimate,
    };
}

let ConnectedDeliveryTimeMenu = connect(
    mapStateToProps,
    { fetchDeliveryTimeEstimate, updateDeliveryTimeEstimate }
)(DeliveryTimeMenu);
export {ConnectedDeliveryTimeMenu as DeliveryTimeMenu}