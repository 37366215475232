import React, { useState } from "react";
import styled from "styled-components";
import {login, logout} from "../../redux/actions";
import { connect } from 'react-redux';

const StyledLoginPageContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 400px 1fr;
    grid-template-rows: 1fr 250px 1fr;
    height: 100%;
`;

const StyledLoginFormContainer = styled.div`
    background-color: #f7f7f7;
    grid-column: 2;
    grid-row: 2;
    padding: 20px;
    border-radius: 5px;
`

const StyledFormListItem = styled.li`
    display: block;
    margin: 10px;
    label {
      margin-right: 20px;
    }
    input {
      float: right;
    }
`

const LoginButton = styled.button`
  float: right;
  margin-right: 10px;
`

function LoginPage (props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    function handleChange(e) {
        if (e.target.name === 'username') {
            setUsername(e.target.value);
        } else if (e.target.name === 'password') {
            setPassword(e.target.value);
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (username && password) {
            props.login(username, password);
        }
    }

    return (
        <StyledLoginPageContainer>
            <StyledLoginFormContainer>
            <h1>Logowanie</h1>
                <form name="login-form" onSubmit={handleSubmit}>
                    <ul>
                        <StyledFormListItem>
                            <label htmlFor="username">Użytkownik</label>
                            <input type="text" name="username" value={username} onChange={handleChange} />
                        </StyledFormListItem>
                        <StyledFormListItem>
                            <label htmlFor="password">Hasło</label>
                            <input type="password" name="password" value={password} onChange={handleChange} />
                        </StyledFormListItem>

                    </ul>
                    <LoginButton>Login</LoginButton>

                <p>{props.errorMsg}</p>
            </form>
            </StyledLoginFormContainer>
        </StyledLoginPageContainer>
    )
}
function mapStateToProps(state, ownProps) {
    return {
        loggedIn: state.auth.loggedIn,
        errorMsg: state.auth.error && state.auth.error.message ? state.auth.error.message : state.auth.error,
        ...ownProps
    };
}

const ConnectedLoginPage = connect(mapStateToProps, { login, logout })(LoginPage);
export { ConnectedLoginPage as LoginPage };