import React from 'react';
import styled from "styled-components";
import { OrderTile } from '../OrderTile/OrderTile';
import { connect } from 'react-redux';

const StyledBoardContent = styled.div`
    grid-area: content;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-flow: column wrap;
    padding: 10px;
    background-color: #f3f3f3;
    min-height: 0;
    overflow-x: auto;
    align-content: flex-start;
    user-select: none;
`;

const StyledCenteredMessageContainer = styled.div`
    position: relative;
    top: 50%;
    left: 0;
    width: 100%;
`;

const StyledCenteredMessage = styled.div`
    display: inline-block;
    text-align: center;
    font-style: italic;
    color: grey;
`

const StyledLoadingSpinner = styled.div`
    border: 10px solid #f3f3f3;
    border-top: 10px solid #1c874f;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 10px;
    text-align: center;

    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }


    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
`
const modeToOrderStatusMap = {
    "KITCHEN": ["KITCHEN_CONFIRMED", "RECEIVED", "READY_TO_PICKUP", "OUT_FOR_DELIVERY"],
    "COMPLETED": ["DELIVERED"],
}

function BoardContent (props) {
    const { mode, error, isLoaded, orders } = props;

    if (error) {
        return <div>Error fetching orders!</div>;
    } else if (!isLoaded) {
        return (
        <StyledCenteredMessageContainer>
            <StyledLoadingSpinner/><br />
            Ładowanie
        </StyledCenteredMessageContainer>
    )}

    const filteredOrders = orders.filter((order) => {return modeToOrderStatusMap[mode].includes(order.status)});
    return (
        <StyledBoardContent>
            {filteredOrders.map(order => (
                <OrderTile order={order} key={order.id}/>
            ))}
            {filteredOrders.length === 0 ? <StyledCenteredMessageContainer>
                <StyledCenteredMessage>Brak zamówień</StyledCenteredMessage>
            </StyledCenteredMessageContainer> : null}
        </StyledBoardContent>
    )
}

function mapStateToProps(state, ownProps) {
    return {
        orders: state.orders.orders,
        error: state.orders.error,
        isLoaded: state.orders.isLoaded,
        mode: ownProps.mode,
    };
}

let ConnectedBoardContent = connect(mapStateToProps)(BoardContent);
export {ConnectedBoardContent as BoardContent}