export const ORDERS_REFRESHED = "ORDERS_REFRESHED";
export const ORDERS_REFRESH_FAILED = "ORDERS_REFRESH_FAILED";
export const ORDERS_REFRESHING = "ORDERS_REFRESHING";
export const SET_MODE = "SET_MODE";
export const ORDER_STATUS_UPDATED = "ORDER_STATUS_UPDATED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const ACCESS_TOKEN_REFRESHED = "ACCESS_TOKEN_REFRESHED";
export const LOGOUT = "LOGOUT";
export const OPEN_ORDER_DETAILS = "OPEN_ORDER_DETAILS";
export const CLOSE_ORDER_DETAILS = "CLOSE_ORDER_DETAILS";
export const OPEN_ORDER_MENU = "OPEN_ORDER_MENU";
export const CLOSE_ORDER_MENU = "CLOSE_ORDER_MENU";
export const DELIVERY_TIME_ESTIMATE_UPDATED = "DELIVERY_TIME_ESTIMATE_UPDATED";
