import React from 'react';
import styled from "styled-components";
import ItemAddOn from "../ItemAddOn/ItemAddOn";

const ItemWrapper = styled.div`
    text-align: left;
    padding: 5px 10px 5px 10px;
`;

const ItemHeaderRow = styled.div``;

const ItemQuantity = styled.span`
    float: right;
`;

const ItemVariant = styled.span`
    padding-left: 10px;
    font-style: italic;
    font-size: 12px ;
`;


function OrderItem (props) {
    const { item } = props;

    return (
        <ItemWrapper>
            <ItemHeaderRow>
                <span>{item.name}</span>{item.variant_name ? <ItemVariant>({item.variant_name})</ItemVariant> : null}<ItemQuantity> x {item.quantity} </ItemQuantity>
            </ItemHeaderRow>
            <div>
                {item.add_ons.map(addOn => (
                    <ItemAddOn addOn={addOn} key={addOn.name}/>
                ))}
            </div>
        </ItemWrapper>
    )
}

export default OrderItem;