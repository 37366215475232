import React, {useState} from 'react';
import styled from "styled-components";

import OrderItem from '../OrderItem/OrderItem.js';
import DeliveryRow from "../DeliveryRow/DeliveryRow";
import {OrderTileMenu} from "../OrderTileMenu/OrderTileMenu";
import OrderTileHeader from "../OrderTileHeader/OrderTileHeader";
import {connect} from "react-redux";
import {openOrderMenu, closeOrderMenu} from "../../redux/actions";


function OrderTileContent (props) {
    const { order } = props;
    return (
        <div>
            <DeliveryRow deliveryInfo={order.delivery} />
            <div>
                {order.items.map((item, idx) => (
                    <OrderItem item={item} key={`${order.id}-${idx}-${item.name}-${item.variant_name}`}/>
                ))}
            </div>
        </div>
    )
}


const StyledTileWrapper = styled.div`
    min-width: 300px;
    padding: 3px;
`;

const StyledOrderTile = styled.div`
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
`;

const StyledOrderTileFooter = styled.div`
  text-transform: uppercase;
      min-height: 20px;
    padding: 5px;
    font-size: 13px;
    font-weight: bold;
        border-top: 2px solid #f3f3f3;
`

function OrderTile (props) {
    const { order, isMenuVisible, openOrderMenu, closeOrderMenu } = props;

    function toggleMenu (e) {
        if (e.target.tagName === "button") {
            return null;
        }
        if (!isMenuVisible) {
            openOrderMenu(order.id);
        } else {
            closeOrderMenu();
        }
    }

    function hideMenu () {
        closeOrderMenu();
    }

    let nextStatus, nextStatusMessage, currentStatusMessage;
    if (order.status === "RECEIVED") {
        currentStatusMessage = "Oczekuje na potwierdzenie";
        nextStatus = "KITCHEN_CONFIRMED";
        nextStatusMessage = "Potwierdź";
    } else if (order.status === "KITCHEN_CONFIRMED") {
        currentStatusMessage = "W przygotowaniu";
        if (order.delivery.delivery_option === "DELIVERY") {
            nextStatus = "OUT_FOR_DELIVERY";
            nextStatusMessage = "W dostawie";
        } else {
            nextStatus = "READY_TO_PICKUP";
            nextStatusMessage = "Gotowe do odbioru"
        }
    } else if (order.status === "OUT_FOR_DELIVERY") {
        currentStatusMessage = "W dostawie";
        nextStatus = "DELIVERED";
        nextStatusMessage = "Dostarczone";
    } else if (order.status === "READY_TO_PICKUP") {
        currentStatusMessage = "Gotowe do odbioru";
        nextStatus = "DELIVERED";
        nextStatusMessage = "Odebrane";
    }

    return (
        <StyledTileWrapper onClick={toggleMenu}>
            <StyledOrderTile>
                <OrderTileHeader order={order}/>
                {isMenuVisible ? <OrderTileMenu orderId={order.id} nextStatus={nextStatus} nextStatusMessage={nextStatusMessage} onClose={hideMenu}/> : null}
                <OrderTileContent order={order}/>
                <StyledOrderTileFooter>{currentStatusMessage}</StyledOrderTileFooter>
            </StyledOrderTile>
        </StyledTileWrapper>
    )
}


function mapTileStateToProps(state, ownProps) {
    return {
        isMenuVisible: state.orders.openMenuOrderId === ownProps.order.id
    };
}

const ConnectedOrderTile = connect(mapTileStateToProps, {openOrderMenu, closeOrderMenu})(OrderTile);
export {ConnectedOrderTile as OrderTile};