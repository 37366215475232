import React from 'react';
import './App.css';
import { Board } from './components/Board/Board.js';
import { LoginPage } from './components/LoginPage/LoginPage.js';
import {
    Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import { history } from "./history";


function App() {
    return (
      <Provider store={store}>
          <Router history={history}>
            <div className="App">
                <Switch>
                    <Redirect exact from="/" to="/kitchen"/>
                    <Route path="/login">
                        <LoginPage/>
                    </Route>
                    <PrivateRoute path="/kitchen" component={Board} componentProps={{mode: "KITCHEN"}} />
                    <PrivateRoute path="/completed" component={Board} componentProps={{mode: "COMPLETED"}} />
                </Switch>
            </div>
          </Router>
      </Provider>

  );
}

export default App;
