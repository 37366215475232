import React from 'react';
import styled from "styled-components";

const PublicIdComponent = styled.span`
    display: inline-block;
    font-weight: bold;
    float: left;
`;

function PublicId (props) {
    return (
        <PublicIdComponent>#{props.publicId}</PublicIdComponent>
    )
}

export default PublicId;