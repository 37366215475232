import React from 'react';
import styled from "styled-components";

const AddOn = styled.div`
    padding-left: 20px;
    font-size: 13px;
`;

function ItemAddOn (props) {
    const { addOn } = props;

    return (
        <AddOn>
            + {addOn.name}
        </AddOn>
    )
}

export default ItemAddOn;